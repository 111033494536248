<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'DamageCoordinates',
  data() {
    return {
      lastCoordinates: {
        x: 0,
        y: 0,
        target: null,
      },
    };
  },
  methods: {
    onClick($event) {
      const { target } = $event || {};

      if (target.classList.contains('DamageSide__image')) {
        this.lastCoordinates.x = $event.offsetX;
        this.lastCoordinates.y = $event.offsetY;
        this.lastCoordinates.target = $event.target;
        this.$emit('getCoordinates', cloneDeep(this.lastCoordinates));
      }
    },
  },
};
</script>

<template>
  <div
    class="DamageCoordinates cursor-pointer"
    @click="onClick"
  >
    <slot />
  </div>
</template>
