<script setup>
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="LeaveWithoutSavingModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        title="Leave without saving?"
        text="Any new damage details won’t be saved."
      />
    </template>
    <template #footer>
      <CancelButton @click="$emit('closeModal')" />
      <ui-button
        :color="COLORS.danger"
        class="wmin-initial"
        @clickbutton="$emit('on:leave')"
      >
        Yes, leave
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
