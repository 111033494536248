var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.GenericModalComponent,
    _vm._g(
      {
        staticClass: "LeaveWithoutSavingModal",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(_setup.DeleteModalBodyComponent, {
                  attrs: {
                    title: "Leave without saving?",
                    text: "Any new damage details won’t be saved.",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(_setup.CancelButton, {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    staticClass: "wmin-initial",
                    attrs: { color: _vm.COLORS.danger },
                    on: {
                      clickbutton: function ($event) {
                        return _vm.$emit("on:leave")
                      },
                    },
                  },
                  [_vm._v(" Yes, leave ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }