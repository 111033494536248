var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.PageView,
    { staticClass: "InvestigationView d-flex flex-fill flex-column" },
    [
      _c("h1", { staticClass: "mb-3" }, [_vm._v(" Investigate damage ")]),
      _setup.isLoading
        ? _c("ui-loader", {
            attrs: {
              label: "Loading investigation...",
              absolute: "",
              "data-test-id": "loader",
            },
          })
        : _vm._e(),
      _c("Transition", { attrs: { name: "page" } }, [
        !_setup.isLoading
          ? _c("div", { staticClass: "d-flex flex-fill" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column flex-fill py-3 px-4 emobg-background-color-white",
                },
                [
                  _c("h1", [_vm._v("Investigation")]),
                  _c("hr", {
                    staticClass:
                      "mt-2 mb-3 emobg-border-top-2 emobg-border-color-ground-light",
                  }),
                  _c("div", { staticClass: "d-flex flex-fill" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mr-3",
                        staticStyle: { flex: "0 0 270px" },
                      },
                      [
                        _c("h3", { staticClass: "mb-1" }, [_vm._v(" Damage ")]),
                        _c("p", { staticClass: "emobg-body-1" }, [
                          _vm._v(" Reported on: "),
                          _c("span", { staticClass: "emobg-color-ink-light" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _setup.reformatDateTime(
                                    _setup.getValue(
                                      _setup.investigation,
                                      "damage.reportingDate",
                                      ""
                                    ),
                                    _setup.DATE_FORMAT.defaultExtended,
                                    _setup.operatorTimezone
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "position-relative",
                            staticStyle: { "max-width": "fit-content" },
                          },
                          [
                            _c(_setup.DamageImageTemplate, {
                              staticClass: "mt-2",
                              attrs: {
                                src: _setup.getValue(
                                  _setup.investigation,
                                  "damage.image",
                                  ""
                                ),
                                height: 436,
                                "is-overlay-hidden":
                                  _setup.isDamageOverlayHidden,
                                "has-border-radius": "",
                              },
                            }),
                            _c(
                              "ui-button",
                              {
                                staticClass: "position-absolute",
                                staticStyle: { right: "8px", bottom: "8px" },
                                attrs: {
                                  color: _vm.GRAYSCALE.inkLight,
                                  face: _vm.FACES.outline,
                                  square: "",
                                },
                                on: {
                                  clickbutton: () =>
                                    (_setup.isDamageOverlayHidden =
                                      !_setup.isDamageOverlayHidden),
                                },
                              },
                              [
                                _c("ui-icon", {
                                  attrs: {
                                    icon: _setup.isDamageOverlayHidden
                                      ? _vm.ICONS.show
                                      : _vm.ICONS.hide,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(_setup.DamageSide, {
                          attrs: {
                            src: _setup.getValue(
                              _setup.investigation,
                              "damage.blueprint",
                              ""
                            ),
                            dots: [
                              {
                                x: _setup.getValue(
                                  _setup.investigation,
                                  "damage.coordinateX"
                                ),
                                y: _setup.getValue(
                                  _setup.investigation,
                                  "damage.coordinateY"
                                ),
                              },
                            ],
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-fill flex-column" },
                      [
                        _c(_setup.InvestigationBookingsGallery, {
                          attrs: {
                            bookings: _setup.investigationBookings,
                            "starting-booking-index":
                              _setup.indexOfBookingWhenClosed,
                            "hide-navigation":
                              _setup.isClosed &&
                              _setup.investigation.resolution ===
                                _setup.INVESTIGATION_RESOLUTION_STATUS
                                  .identified,
                            "disable-navigation": _setup.isIdentifiedStatus,
                          },
                          on: {
                            "active:booking": (booking) =>
                              (_setup.activeBooking = booking),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column p-3 emobg-background-color-ground-lightest",
                  staticStyle: { flex: "0 0 378px" },
                },
                [
                  _c("h1", [_vm._v("Resolution ")]),
                  _c("hr", {
                    staticClass:
                      "mt-2 mb-3 emobg-border-top-2 emobg-border-color-ground-light",
                  }),
                  _c("p", { staticClass: "emobg-color-ink-light mb-3" }, [
                    _vm._v(
                      " Review the vehicle status pictures to identify the booking that caused the damage. "
                    ),
                  ]),
                  _c("ui-validate", [
                    _setup.isClosed
                      ? _c(
                          "div",
                          { staticClass: "mb-3" },
                          [
                            _c(
                              _setup.ContentCellComponent,
                              { attrs: { label: "Resolution status" } },
                              [
                                _c(_setup.InvestigationStatusBadge, {
                                  attrs: {
                                    status: _setup.inputs.resolution,
                                    "is-resolution": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "ui-select",
                              {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate.input",
                                    value: {
                                      isRequired: true,
                                    },
                                    expression:
                                      "{\n                isRequired: true,\n              }",
                                    modifiers: { input: true },
                                  },
                                ],
                                ref: "resolutionSelect",
                                staticClass: "d-block w-100 mb-3",
                                attrs: {
                                  value: _setup.inputs.resolution,
                                  label: "Resolution status*",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "selected-component" },
                                    slot: "selected-component",
                                  },
                                  [
                                    _setup.inputs.resolution
                                      ? _c(_setup.InvestigationStatusBadge, {
                                          attrs: {
                                            status: _setup.inputs.resolution,
                                            "is-resolution": "",
                                          },
                                        })
                                      : _c(
                                          "span",
                                          {
                                            staticClass:
                                              "emobg-color-ink-light",
                                          },
                                          [
                                            _vm._v(
                                              " Select the resolution status "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c(
                                      "ui-button",
                                      {
                                        staticClass:
                                          "d-block w-100 emobg-border-bottom-2 emobg-border-color-ground-light",
                                        attrs: {
                                          face: _vm.FACES.text,
                                          compact: "",
                                        },
                                        on: {
                                          clickbutton: function ($event) {
                                            _setup.inputs.resolution = null
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "mx-2" }, [
                                          _vm._v(" Clear selection "),
                                        ]),
                                      ]
                                    ),
                                    _vm._l(
                                      _setup.investigationResolutionStatusOptions,
                                      function (statusOption) {
                                        return _c(
                                          "ui-select-item",
                                          {
                                            key: statusOption,
                                            attrs: {
                                              label:
                                                _setup.sentenceCase(
                                                  statusOption
                                                ),
                                              value: statusOption,
                                              selected:
                                                _setup.inputs.resolution ===
                                                statusOption,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _setup.inputs.resolution =
                                                  statusOption
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              _setup.InvestigationStatusBadge,
                                              {
                                                attrs: {
                                                  status: statusOption,
                                                  "is-resolution": "",
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                    _c(
                      "div",
                      [
                        _setup.isIdentifiedStatus && _setup.activeBooking
                          ? _c(_setup.ContentCellComponent, {
                              staticClass: "mb-3",
                              attrs: {
                                value: _setup.activeBooking
                                  ? `#${_setup.activeBooking.id}`
                                  : "",
                                label: "Identified booking",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _setup.isClosed
                          ? _c(_setup.ContentCellComponent, {
                              attrs: {
                                value: _setup.inputs.resolutionReason,
                                label: "Resolution reason",
                              },
                            })
                          : _setup.inputs.resolution
                          ? _c("ui-text-area", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate.input",
                                  value: {
                                    isRequired: true,
                                    isMinLength: {
                                      message: "We need at least 6 characters",
                                      length: 6,
                                    },
                                  },
                                  expression:
                                    "{\n                isRequired: true,\n                isMinLength: {\n                  message: 'We need at least 6 characters',\n                  length: 6,\n                },\n              }",
                                  modifiers: { input: true },
                                },
                              ],
                              staticStyle: { display: "block", height: "auto" },
                              attrs: {
                                value: _setup.inputs.resolutionReason,
                                rows: 6,
                                label: "Resolution reason*",
                                placeholder:
                                  "Explain the reason for the resolution",
                              },
                              on: {
                                changevalue: ({ detail }) =>
                                  (_setup.inputs.resolutionReason = detail),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("hr", {
                    staticClass:
                      "my-3 emobg-border-top-2 emobg-border-color-ground-light",
                  }),
                  _c("div", { staticClass: "position-relative flex-fill" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "position-absolute w-100 h-100 overflow-y-auto",
                      },
                      [
                        _c(_setup.CommentsList, {
                          staticClass: "mb-4",
                          attrs: {
                            comments: _setup.comments,
                            "is-loading": _setup.commentsStatus.LOADING,
                            "entity-uuid": _setup.investigation.uuid,
                            callback: _setup.getInvestigationComments,
                            entity: _setup.COMMENTABLE_TYPES.investigation,
                            disabled: _setup.isClosed,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      !_setup.isLoading
        ? _c(
            "div",
            {
              staticClass:
                "BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky",
            },
            [
              _c(
                "ui-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: _vm.GRAYSCALE.inkLight,
                    face: _vm.FACES.text,
                    "data-test-id": "close-button",
                  },
                  on: { clickbutton: _setup.redirectToDamagePage },
                },
                [_vm._v(" Close ")]
              ),
              !_setup.isClosed
                ? _c(
                    "ui-button",
                    {
                      attrs: {
                        disabled:
                          !_setup.areAllRequiredFieldsFilled ||
                          _setup.updateInvestigationStatus.LOADING,
                        loading: _setup.updateInvestigationStatus.LOADING,
                        "data-test-id": "save-button",
                      },
                      on: { clickbutton: _setup.finishInvestigation },
                    },
                    [_vm._v(" Finish investigation ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }