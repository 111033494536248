<script setup>
import findIndex from 'lodash/findIndex';
import invoke from 'lodash/invoke';
import pick from 'lodash/pick';
import camelCase from 'lodash/camelCase';
import {
  computed,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import {
  DATE_FORMAT,
  getValue,
  isNullValue,
  navigationErrorHandler,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CommentsList, ContentCellComponent, PageView } from '@/components';
import { NOT_FOUND } from '@/constants/urls';
import { useStoreModule } from '@/store/composable/useStoreModule';
import { Validate as vValidate } from '@emobg/vue-base';
import fleet from '@domains/Carsharing/router/FleetRouterMap';
import { COMMENTABLE_TYPES } from '@/components/Comments/v1/constants/comments';
import DamageImageTemplate from '../components/DamageImageTemplate';
import DamageSide from '../components/DamageSide';
import { INVESTIGATION_SCOPES } from './store/InvestigationModule';
import InvestigationStatusBadge from './components/InvestigationStatusBadge';
import InvestigationBookingsGallery from './components/InvestigationBookingsGallery';
import { INVESTIGATION_RESOLUTION_STATUS } from './const/investigations.const';

const {
  investigation,
  investigationBookings,
  getInvestigation,
  getInvestigationBookings,
  updateInvestigationStatus,
  putInvestigation,
} = useStoreModule(DOMAINS_MODEL.carsharing.investigation, {
  state: {
    investigation: state => state[INVESTIGATION_SCOPES.investigation].data,
    investigationBookings: state => state[INVESTIGATION_SCOPES.investigationBookings].data,
    updateInvestigationStatus: state => state[INVESTIGATION_SCOPES.updateInvestigation].STATUS,
  },
  actions: [
    'getInvestigation',
    'getInvestigationBookings',
    'putInvestigation',
  ],
});
const { add: notify } = useStoreModule(DOMAINS_MODEL.app.messages.notifications.index, {
  mutations: [
    'add',
  ],
});
const {
  comments,
  commentsStatus,
  getCommentsByType,
  clearData,
} = useStoreModule(DOMAINS_MODEL.app.comments, {
  state: {
    comments: state => state.comments.data || [],
    commentsStatus: state => state.comments.STATUS,
  },
  actions: ['getCommentsByType'],
  mutations: ['clearData'],
});

const { operatorTimezone } = useStoreModule(DOMAINS_MODEL.app.userAccount, {
  state: {
    operatorTimezone: state => state.operators.active.timezone,
  },
});
const router = useRouter();
const props = defineProps({
  vehicleUuid: {
    type: String,
    required: true,
  },
  damageUuid: {
    type: String,
    required: true,
  },
});
const isLoading = ref(true);
const isDamageOverlayHidden = ref(true);
const resolutionSelect = ref(null);
const inputs = reactive({
  resolution: null,
  resolutionReason: '',
  bookingUuid: null,
});
const activeBooking = ref(null);
const isIdentifiedStatus = computed(() => inputs.resolution === INVESTIGATION_RESOLUTION_STATUS.identified);
const isClosed = computed(() => !isNullValue(investigation.value.resolution));
const indexOfBookingWhenClosed = computed(() => {
  if (!isClosed.value || isNullValue(investigation.value.bookingUuid)) {
    return 0;
  }

  return findIndex(investigationBookings.value, booking => booking.uuid === investigation.value.bookingUuid);
});
const areAllRequiredFieldsFilled = computed(() => {
  const hasValidResolutionReason = inputs.resolutionReason && inputs.resolutionReason.length > 5;
  const statusCases = {
    [INVESTIGATION_RESOLUTION_STATUS.identified]: inputs.bookingUuid && hasValidResolutionReason,
    [INVESTIGATION_RESOLUTION_STATUS.notIdentified]: !inputs.bookingUuid && hasValidResolutionReason,
  };

  return !!(inputs.resolution && statusCases[inputs.resolution]);
});
const investigationResolutionStatusOptions = computed(() => (investigationBookings.value.length
  ? INVESTIGATION_RESOLUTION_STATUS
  : pick(INVESTIGATION_RESOLUTION_STATUS, [camelCase(INVESTIGATION_RESOLUTION_STATUS.notIdentified)])));

watch(() => inputs.resolution, () => {
  const selectElement = resolutionSelect.value;
  invoke(selectElement, 'dispatchEvent', new CustomEvent('validate', { bubbles: true, cancelable: true }));
  inputs.bookingUuid = isIdentifiedStatus.value ? getValue(activeBooking.value, 'uuid', null) : null;
  inputs.resolutionReason = !inputs.resolution ? '' : inputs.resolutionReason;
});

const redirectToDamagePage = () => {
  router.push({
    name: fleet.damages.edit,
    params: {
      vehicleUuid: props.vehicleUuid,
      damageUuid: props.damageUuid,
    },
  }).catch(navigationErrorHandler);
};

const finishInvestigation = async () => {
  await putInvestigation({ damageUuid: props.damageUuid, data: inputs });

  if (!updateInvestigationStatus.value.ERROR) {
    notify({ message: 'Investigation successfully <span class="emobg-font-weight-semibold">finished</span>' });
    redirectToDamagePage();
  }
};

const getInvestigationComments = async () => {
  clearData();
  await getCommentsByType({
    commentableUuid: getValue(investigation.value, 'uuid', undefined),
    commentableType: COMMENTABLE_TYPES.investigation,
  });
};

onBeforeMount(async () => {
  await Promise.all([
    getInvestigation(props.damageUuid),
    getInvestigationBookings(props.damageUuid),
  ]);

  if (!getValue(investigation.value, 'uuid', null)) {
    router.push(NOT_FOUND).catch(navigationErrorHandler);
    return;
  }

  inputs.resolution = getValue(investigation.value, 'resolution', null);
  inputs.resolutionReason = getValue(investigation.value, 'resolutionReason', '');
  inputs.bookingUuid = getValue(investigation.value, 'bookingUuid', null);

  getInvestigationComments();
  isLoading.value = false;
});
</script>

<template>
  <PageView class="InvestigationView d-flex flex-fill flex-column">
    <h1 class="mb-3">
      Investigate damage
    </h1>
    <ui-loader
      v-if="isLoading"
      label="Loading investigation..."
      absolute
      data-test-id="loader"
    />
    <Transition name="page">
      <div
        v-if="!isLoading"
        class="d-flex flex-fill"
      >
        <div class="d-flex flex-column flex-fill py-3 px-4 emobg-background-color-white">
          <h1>Investigation</h1>
          <hr class="mt-2 mb-3 emobg-border-top-2 emobg-border-color-ground-light">
          <div class="d-flex flex-fill">
            <div
              class="mr-3"
              style="flex: 0 0 270px;"
            >
              <h3 class="mb-1">
                Damage
              </h3>
              <p class="emobg-body-1">
                Reported on:
                <span class="emobg-color-ink-light">
                  {{ reformatDateTime(getValue(investigation, 'damage.reportingDate', ''), DATE_FORMAT.defaultExtended, operatorTimezone) }}
                </span>
              </p>
              <div
                class="position-relative"
                style="max-width: fit-content;"
              >
                <DamageImageTemplate
                  :src="getValue(investigation, 'damage.image', '')"
                  :height="436"
                  :is-overlay-hidden="isDamageOverlayHidden"
                  has-border-radius
                  class="mt-2"
                />
                <ui-button
                  :color="GRAYSCALE.inkLight"
                  :face="FACES.outline"
                  square
                  class="position-absolute"
                  style="right: 8px; bottom: 8px;"
                  @clickbutton="() => isDamageOverlayHidden = !isDamageOverlayHidden"
                >
                  <ui-icon :icon="isDamageOverlayHidden ? ICONS.show : ICONS.hide" />
                </ui-button>
              </div>
              <DamageSide
                :src="getValue(investigation, 'damage.blueprint', '')"
                :dots="[
                  {
                    x: getValue(investigation, 'damage.coordinateX'),
                    y: getValue(investigation, 'damage.coordinateY'),
                  },
                ]"
              />
            </div>
            <div class="d-flex flex-fill flex-column">
              <InvestigationBookingsGallery
                :bookings="investigationBookings"
                :starting-booking-index="indexOfBookingWhenClosed"
                :hide-navigation="isClosed && investigation.resolution === INVESTIGATION_RESOLUTION_STATUS.identified"
                :disable-navigation="isIdentifiedStatus"
                @active:booking="booking => activeBooking = booking"
              />
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column p-3 emobg-background-color-ground-lightest"
          style="flex: 0 0 378px;"
        >
          <h1>Resolution </h1>
          <hr class="mt-2 mb-3 emobg-border-top-2 emobg-border-color-ground-light">
          <p class="emobg-color-ink-light mb-3">
            Review the vehicle status pictures to identify the booking that caused the damage.
          </p>
          <ui-validate>
            <div
              v-if="isClosed"
              class="mb-3"
            >
              <ContentCellComponent label="Resolution status">
                <InvestigationStatusBadge
                  :status="inputs.resolution"
                  is-resolution
                />
              </ContentCellComponent>
            </div>
            <div v-else>
              <ui-select
                ref="resolutionSelect"
                v-validate.input="{
                  isRequired: true,
                }"
                :value="inputs.resolution"
                label="Resolution status*"
                class="d-block w-100 mb-3"
              >
                <div slot="selected-component">
                  <InvestigationStatusBadge
                    v-if="inputs.resolution"
                    :status="inputs.resolution"
                    is-resolution
                  />
                  <span
                    v-else
                    class="emobg-color-ink-light"
                  >
                    Select the resolution status
                  </span>
                </div>
                <div slot="content">
                  <ui-button
                    :face="FACES.text"
                    class="d-block w-100 emobg-border-bottom-2 emobg-border-color-ground-light"
                    compact
                    @clickbutton="inputs.resolution = null"
                  >
                    <div class="mx-2">
                      Clear selection
                    </div>
                  </ui-button>
                  <ui-select-item
                    v-for="statusOption in investigationResolutionStatusOptions"
                    :key="statusOption"
                    :label="sentenceCase(statusOption)"
                    :value="statusOption"
                    :selected="inputs.resolution === statusOption"
                    @click="inputs.resolution = statusOption"
                  >
                    <InvestigationStatusBadge
                      :status="statusOption"
                      is-resolution
                    />
                  </ui-select-item>
                </div>
              </ui-select>
            </div>

            <div>
              <ContentCellComponent
                v-if="isIdentifiedStatus && activeBooking"
                :value="activeBooking ? `#${activeBooking.id}` : ''"
                label="Identified booking"
                class="mb-3"
              />
            </div>

            <div>
              <ContentCellComponent
                v-if="isClosed"
                :value="inputs.resolutionReason"
                label="Resolution reason"
              />
              <ui-text-area
                v-else-if="inputs.resolution"
                v-validate.input="{
                  isRequired: true,
                  isMinLength: {
                    message: 'We need at least 6 characters',
                    length: 6,
                  },
                }"
                :value="inputs.resolutionReason"
                :rows="6"
                style="display: block; height: auto;"
                label="Resolution reason*"
                placeholder="Explain the reason for the resolution"
                @changevalue="({ detail }) => inputs.resolutionReason = detail"
              />
            </div>
          </ui-validate>

          <hr class="my-3 emobg-border-top-2 emobg-border-color-ground-light">

          <div class="position-relative flex-fill">
            <div class="position-absolute w-100 h-100 overflow-y-auto">
              <CommentsList
                :comments="comments"
                :is-loading="commentsStatus.LOADING"
                :entity-uuid="investigation.uuid"
                :callback="getInvestigationComments"
                :entity="COMMENTABLE_TYPES.investigation"
                :disabled="isClosed"
                class="mb-4"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <div
      v-if="!isLoading"
      class="BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky"
    >
      <ui-button
        :color="GRAYSCALE.inkLight"
        :face="FACES.text"
        class="mr-2"
        data-test-id="close-button"
        @clickbutton="redirectToDamagePage"
      >
        Close
      </ui-button>
      <ui-button
        v-if="!isClosed"
        :disabled="!areAllRequiredFieldsFilled || updateInvestigationStatus.LOADING"
        :loading="updateInvestigationStatus.LOADING"
        data-test-id="save-button"
        @clickbutton="finishInvestigation"
      >
        Finish investigation
      </ui-button>
    </div>
  </PageView>
</template>
<style lang="scss">
.InvestigationView {
  .BottomActions {
    bottom: 0;
    z-index: 1;
    align-items: center;
    justify-content: end;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32);
  }

  .Ui-TextInput--disabled {
    background-color: initial;
  }

  .Ui-TextArea textarea {
    resize: none;
  }

  .CommentsList__comments {
    max-height: 100%;
    overflow: initial;
  }
}
</style>
