<script setup>
import {
  DATE_FORMAT, FALLBACK_MESSAGE, getValue, mapObject, reformatDateTime,
} from '@emobg/web-utils';
import { computed, ref, watch } from 'vue';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import GalleryComponent from '@/components/Gallery/GalleryComponent';
import { useStoreModule } from '@/store/composable/useStoreModule';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
const props = defineProps({
  bookings: {
    type: Array,
    default: () => [],
  },
  disableNavigation: {
    type: Boolean,
    default: false,
  },
  hideNavigation: {
    type: Boolean,
    default: false,
  },
  startingBookingIndex: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(['active:booking']);

const { operatorTimezone } = useStoreModule(DOMAINS_MODEL.app.userAccount, {
  state: {
    operatorTimezone: state => state.operators.active.timezone,
  },
});

const imageIndex = ref(0);
const bookingIndex = ref(props.startingBookingIndex);
const activeBooking = computed(() => getValue(props.bookings, `[${bookingIndex.value}]`, null));
const activeBookingImages = computed(() => mapObject(getValue(activeBooking.value, 'images') || [], (src) => ({ src })));
const activeBookingRealEnd = computed(() => {
  const realEnd = getValue(activeBooking.value, 'realEnd', '');
  return realEnd ? reformatDateTime(realEnd, DATE_FORMAT.defaultExtended, operatorTimezone.value) : FALLBACK_MESSAGE.dash;
});

watch(bookingIndex, () => {
  imageIndex.value = 0;
  emits('active:booking', activeBooking.value);
}, { immediate: true });

</script>
<template>
  <div class="InvestigationBookingsGallery d-flex flex-fill flex-column">
    <span class="emobg-font-weight-semibold">Vehicle status</span>
    <p class="my-1">
      Real end: <span class="emobg-color-ink-light"> {{ activeBookingRealEnd }} </span> Booking ID:
      <RouterLink
        v-if="activeBooking"
        :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingBookings]"
        :to="{
          name: carsharingRoutes.bookings.detail.index,
          params: {
            bookingUuid: activeBooking.uuid,
          },
        }"
        target="_blank"
        class="cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none"
        data-test-id="agent-link"
      >
        {{ `#${activeBooking.id}` }}
      </RouterLink>
      <span v-else>
        {{ FALLBACK_MESSAGE.dash }}
      </span>
    </p>
    <GalleryComponent
      v-if="activeBookingImages.length"
      :images="activeBookingImages"
      :start-index="imageIndex"
      data-test-id="gallery-component"
      class="h-100"
      custom-navigation
      zoomable
    >
      <div class="d-flex">
        <div
          v-for="(image, index) in activeBookingImages"
          :key="image.src"
          class="InvestigationBookingsGallery__thumbnail emobg-border-radius-medium emobg-border-color-white emobg-border-1 emobg-border-style-solid mx-1"
          :style="{
            backgroundImage: `url('${image.src}')`,
          }"
          @click="imageIndex = index"
        />
      </div>
    </GalleryComponent>
    <div
      v-else
      class="GalleryComponent d-flex h-100 justify-content-center align-items-center
      emobg-border-1 emobg-border-color-ground emobg-border-radius-small
      emobg-background-color-ground-lighter"
    >
      <h3>{{ bookings.length ? 'This booking has no pictures available' : 'There are no previous bookings' }}</h3>
    </div>
    <div
      :class="[
        'InvestigationBookingsGallery__pagination d-flex align-items-center mt-2',
        `${ hideNavigation ? 'justify-content-center' : 'justify-content-between'}`,
      ]"
    >
      <ui-button
        v-if="!hideNavigation"
        :face="FACES.text"
        :disabled="(bookingIndex - 1 < 0) || disableNavigation"
        compact
        @clickbutton="bookingIndex--"
      >
        <ui-icon :icon="ICONS.arrowLeft" /> Previous booking
      </ui-button>
      <ui-badge :color="GRAYSCALE.ground">
        Booking {{ bookings.length ? bookingIndex + 1 : 0 }} of {{ bookings.length }}
      </ui-badge>
      <ui-button
        v-if="!hideNavigation"
        :face="FACES.text"
        :disabled="(bookingIndex + 1 >= bookings.length) || disableNavigation"
        compact
        @clickbutton="bookingIndex++"
      >
        Next booking <ui-icon :icon="ICONS.arrowRight" />
      </ui-button>
    </div>
  </div>
</template>
<style lang="scss">
.InvestigationBookingsGallery {
  &__thumbnail {
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-position: center;
    background-size: cover;

    &:hover {
      filter: brightness(0.4);
    }
  }

  .GalleryComponent {
    position: relative;
    width: 100%;

    &__actions {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 16px;
    }
  }
}
</style>
