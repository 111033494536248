import { RECORD_TYPES } from '@emobg/motion-ui';
import { PermissionLink } from '@/components';
import {
  DATE_FORMAT, FALLBACK_MESSAGE, getValue, reformatDateTime,
} from '@emobg/web-utils';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import InvestigationStatusBadge from '../Investigation/components/InvestigationStatusBadge';

export const INVESTIGATION_SCHEMA = timezone => ([
  {
    header: 'Agent',
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: ({ agent }) => {
      const userUuid = getValue(agent, 'userUuid', null);
      const userName = `${getValue(agent, 'firstName', '')} ${getValue(agent, 'lastName', '')}`;

      return {
        classLink: 'd-block ellipsis emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.index,
          params: {
            userUuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: userUuid ? userName : FALLBACK_MESSAGE.dash,
      };
    },
  },
  {
    header: 'Start date',
    template: ({ startDate }) => startDate ? reformatDateTime(startDate, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash,
  },
  {
    header: 'End date',
    template: ({ endDate }) => endDate ? reformatDateTime(endDate, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Investigation',
    type: RECORD_TYPES.component,
    component: InvestigationStatusBadge,
    props: ({ status }) => ({ status }),
  },
  {
    header: 'Resolution',
    type: RECORD_TYPES.component,
    component: InvestigationStatusBadge,
    props: ({ resolutionStatus }) => ({ status: resolutionStatus || '', isResolution: true }),
  },
]);
