<script>
import sharedRoutes from '@domains/Shared/router/SharedRouterMap';
import { getValue, navigationErrorHandler } from '@emobg/web-utils';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { VEHICLE_DAMAGES_SCOPES } from '@domains/Carsharing/Vehicles/VehicleDamages/store/VehicleDamagesModule';
import { PageView } from '@/components';

export default {
  name: 'DamageView',
  components: {
    PageView,
  },
  beforeRouteLeave(_to, _from, next) {
    this.setData({ scope: VEHICLE_DAMAGES_SCOPES.vehicle, value: null });
    this.setData({ scope: VEHICLE_DAMAGES_SCOPES.damage, value: null });
    next();
  },
  props: {
    vehicleUuid: {
      type: String,
      required: true,
    },
    damageUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.vehicleDamages, {
      vehicle: state => state.vehicle.data,
      damage: state => state.damage.data,
      isDamageLoading: state => state.damage.STATUS.LOADING,
    }),
  },
  watch: {
    async damageUuid() {
      await this.fetchDamage();
    },
  },
  async created() {
    await this.getVehicleDetails(this.vehicleUuid);
    this.redirectIfResourceNotFound(this.vehicle);

    if (this.damageUuid) {
      await this.fetchDamage();
    }

    this.isLoading = false;
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.carsharing.vehicleDamages, [
      'setData',
    ]),
    ...mapActions(DOMAINS_MODEL.carsharing.vehicleDamages, [
      'getVehicleDetails',
      'getVehicleDamage',
    ]),
    async fetchDamage() {
      this.isLoading = true;
      await this.getVehicleDamage({ damageUuid: this.damageUuid });
      this.redirectIfResourceNotFound(getValue(this, 'damage.uuid', null));
      this.isLoading = false;
    },
    redirectIfResourceNotFound(resource) {
      if (!resource) {
        this.$router.push({ name: sharedRoutes.notFound }).catch(navigationErrorHandler);
      }
    },
  },
};
</script>
<template>
  <PageView
    class="DamagesReportView d-flex flex-column flex-fill"
    :class="{
      'justify-content-center': isLoading || isDamageLoading,
    }"
  >
    <ui-loader
      v-if="isLoading || isDamageLoading"
      label="Loading..."
    />
    <Transition
      v-else
      name="page"
      mode="out-in"
    >
      <RouterView />
    </Transition>
  </PageView>
</template>
