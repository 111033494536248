var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "InvestigationBookingsGallery d-flex flex-fill flex-column",
    },
    [
      _c("span", { staticClass: "emobg-font-weight-semibold" }, [
        _vm._v("Vehicle status"),
      ]),
      _c(
        "p",
        { staticClass: "my-1" },
        [
          _vm._v(" Real end: "),
          _c("span", { staticClass: "emobg-color-ink-light" }, [
            _vm._v(" " + _vm._s(_setup.activeBookingRealEnd) + " "),
          ]),
          _vm._v(" Booking ID: "),
          _setup.activeBooking
            ? _c(
                "RouterLink",
                {
                  staticClass:
                    "cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none",
                  attrs: {
                    "link-permissions": [
                      _setup.CARSHARING_PERMISSIONS.viewCarsharingBookings,
                    ],
                    to: {
                      name: _setup.carsharingRoutes.bookings.detail.index,
                      params: {
                        bookingUuid: _setup.activeBooking.uuid,
                      },
                    },
                    target: "_blank",
                    "data-test-id": "agent-link",
                  },
                },
                [_vm._v(" " + _vm._s(`#${_setup.activeBooking.id}`) + " ")]
              )
            : _c("span", [
                _vm._v(" " + _vm._s(_setup.FALLBACK_MESSAGE.dash) + " "),
              ]),
        ],
        1
      ),
      _setup.activeBookingImages.length
        ? _c(
            _setup.GalleryComponent,
            {
              staticClass: "h-100",
              attrs: {
                images: _setup.activeBookingImages,
                "start-index": _setup.imageIndex,
                "data-test-id": "gallery-component",
                "custom-navigation": "",
                zoomable: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                _vm._l(_setup.activeBookingImages, function (image, index) {
                  return _c("div", {
                    key: image.src,
                    staticClass:
                      "InvestigationBookingsGallery__thumbnail emobg-border-radius-medium emobg-border-color-white emobg-border-1 emobg-border-style-solid mx-1",
                    style: {
                      backgroundImage: `url('${image.src}')`,
                    },
                    on: {
                      click: function ($event) {
                        _setup.imageIndex = index
                      },
                    },
                  })
                }),
                0
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass:
                "GalleryComponent d-flex h-100 justify-content-center align-items-center emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-ground-lighter",
            },
            [
              _c("h3", [
                _vm._v(
                  _vm._s(
                    _vm.bookings.length
                      ? "This booking has no pictures available"
                      : "There are no previous bookings"
                  )
                ),
              ]),
            ]
          ),
      _c(
        "div",
        {
          class: [
            "InvestigationBookingsGallery__pagination d-flex align-items-center mt-2",
            `${
              _vm.hideNavigation
                ? "justify-content-center"
                : "justify-content-between"
            }`,
          ],
        },
        [
          !_vm.hideNavigation
            ? _c(
                "ui-button",
                {
                  attrs: {
                    face: _vm.FACES.text,
                    disabled:
                      _setup.bookingIndex - 1 < 0 || _vm.disableNavigation,
                    compact: "",
                  },
                  on: {
                    clickbutton: function ($event) {
                      _setup.bookingIndex--
                    },
                  },
                },
                [
                  _c("ui-icon", { attrs: { icon: _vm.ICONS.arrowLeft } }),
                  _vm._v(" Previous booking "),
                ],
                1
              )
            : _vm._e(),
          _c("ui-badge", { attrs: { color: _vm.GRAYSCALE.ground } }, [
            _vm._v(
              " Booking " +
                _vm._s(_vm.bookings.length ? _setup.bookingIndex + 1 : 0) +
                " of " +
                _vm._s(_vm.bookings.length) +
                " "
            ),
          ]),
          !_vm.hideNavigation
            ? _c(
                "ui-button",
                {
                  attrs: {
                    face: _vm.FACES.text,
                    disabled:
                      _setup.bookingIndex + 1 >= _vm.bookings.length ||
                      _vm.disableNavigation,
                    compact: "",
                  },
                  on: {
                    clickbutton: function ($event) {
                      _setup.bookingIndex++
                    },
                  },
                },
                [
                  _vm._v(" Next booking "),
                  _c("ui-icon", { attrs: { icon: _vm.ICONS.arrowRight } }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }