<script setup>
import { computed, defineProps } from 'vue';
import { useRouter } from 'vue-router/composables';
import { TableComponent } from '@/components';
import { INVESTIGATION_SCHEMA } from '@domains/Carsharing/Vehicles/VehicleDamages/components/investigationTable.config';
import { getValue, isNullValue, navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import fleet from '@domains/Carsharing/router/FleetRouterMap';
import { useStoreModule } from '@/store/composable/useStoreModule';

const router = useRouter();
const props = defineProps({
  investigation: {
    type: [Object, null],
    default: null,
  },
  damageUuid: {
    type: String,
    required: true,
  },
  vehicleUuid: {
    type: String,
    required: true,
  },
});
const { operatorTimezone } = useStoreModule(DOMAINS_MODEL.app.userAccount, {
  state: {
    operatorTimezone: state => state.operators.active.timezone,
  },
});
const investigationData = computed(() => (props.investigation ? [props.investigation] : []));
const isResolvedInvestigation = computed(() => !isNullValue(getValue(props.investigation, 'resolutionStatus', null)));
const investigationSchema = computed(() => INVESTIGATION_SCHEMA(operatorTimezone.value));
const actions = [{
  label: isResolvedInvestigation.value ? 'View investigation' : 'Edit investigation',
  action: () => {
    router.push({
      name: fleet.damages.investigation,
      params: {
        vehicleUuid: props.vehicleUuid,
        damageUuid: props.damageUuid,
      },
    }).catch(navigationErrorHandler);
  },
}];
</script>
<template>
  <div class="InvestigationTableComponent">
    <TableComponent
      :schema="investigationSchema"
      :data="investigationData"
      :row-actions="actions"
      empty-label="No investigation has started yet"
    />
  </div>
</template>
