<script>
import moment from 'moment-timezone';
import upperFirst from 'lodash/upperFirst';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';
import invoke from 'lodash/invoke';
import {
  areObjectsEqual,
  base64WithoutName,
  camelCaseKeys,
  DATE_FORMAT,
  DATE_FORMAT_KEYS,
  FILE_TYPES,
  getImageName,
  getValue,
  navigationErrorHandler,
  pickObjectValue,
  reformatDateTime,
  removeBase64Header,
  sentenceCase,
} from '@emobg/web-utils';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiDatePicker } from '@emobg/vue-base';
import {
  MuiAlgoliaSelect,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import {
  ContentCellComponent,
  DragFileComponent,
  PermissionLink,
  TableComponent,
} from '@/components';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { DATE_INPUT_VALIDATIONS, downloadFile } from '@/utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { INVESTIGATION_STATUS } from '@domains/Carsharing/Vehicles/VehicleDamages/Investigation/const/investigations.const';
import fleet from '../../../router/FleetRouterMap';
import crm from '../../../../CRM/router/CRMRouterMap';
import {
  DAMAGE_ATTACHMENT_SCHEMA,
  DAMAGES_STATUS,
  DAMAGES_STATUS_COLORS,
  DAMAGES_TYPES,
  EXTERIOR_DAMAGE_TYPES,
} from '../damages.const';
import AttachmentPreviewModal from './VehicleDamageAttachments/AttachmentPreviewModal';
import DeleteAttachmentModal from './VehicleDamageAttachments/DeleteAttachmentModal';
import VehicleDamageArea from './VehicleDamageArea/VehicleDamageArea';
import DamageImageUploader from './DamageImageUploader';
import LegalCompliantGuardModal from './LegalCompliantGuardModal';
import InvestigationTableComponent from './InvestigationTableComponent';
import LeaveWithoutSavingModal from './LeaveWithoutSavingModal';
import { INVESTIGATION_SCOPES } from '../Investigation/store/InvestigationModule';

export default {
  name: 'VehicleDamageForm',
  directives: {
    Validate,
  },
  components: {
    LegalCompliantGuardModal,
    PermissionLink,
    MuiValidationWrapper,
    ContentCellComponent,
    MuiTextarea,
    MuiAlgoliaSelect,
    MuiDatePicker,
    MuiSelect,
    DragFileComponent,
    TableComponent,
    AttachmentPreviewModal,
    DeleteAttachmentModal,
    VehicleDamageArea,
    DamageImageUploader,
    InvestigationTableComponent,
    LeaveWithoutSavingModal,
  },
  beforeRouteLeave(to, _from, next) {
    if (this.hasSameValues) {
      next();
    } else {
      this.isLeaveModalOpen = true;
      this.leaveWithoutSaving = next;
    }
  },
  props: {
    vehicleUuid: {
      type: String,
      required: true,
    },
    damageUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLeaveModalOpen: false,
      leaveWithoutSaving: () => {},
      isFormValid: false,
      initialInputs: null,
      isEditingStatus: false,
      isDeleteAttachmentOpen: false,
      attachmentToDelete: null,
      isPreviewModalOpen: false,
      isLegalCompliantGuardModalOpen: false,
      startIndex: 0,
      attachments: [],
      attachmentsToDelete: [],
      currentAttachments: [],
      inputs: {
        status: DAMAGES_STATUS.pendingReview,
        damageImage: null,
        damage: {
          side: null,
          coordinateX: null,
          coordinateY: null,
        },
        vehicleArea: null,
        damageType: null,
        description: '',
        reportingUserUuid: null,
        reportingDate: null,
        responsibleUserUuid: null,
        bookingUuid: null,
        invoiceUuid: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userAccount: state => camelCaseKeys(state.user.data),
      activeOperatorUuid: state => state.operators.active.uuid,
      activeOperatorId: state => state.operators.active.id,
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicleDamages, {
      vehicle: state => state.vehicle.data,
      damage: state => state.damage.data,
      newDamageStatus: state => state.newDamage.STATUS,
      newDamageUuid: state => getValue(state, 'newDamage.data.data.uuid', ''),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.investigation, {
      newInvestigationStatus: state => state[INVESTIGATION_SCOPES.createInvestigation].STATUS,
    }),
    filteredDamageStatuses() {
      return this.hasInvestigationClosed
        ? pickObjectValue(DAMAGES_STATUS, ['notRepaired', 'repaired'])
        : DAMAGES_STATUS;
    },
    responsibleUser() {
      return getValue(this, 'damage.responsibility.user', null);
    },
    responsibleBooking() {
      return getValue(this, 'damage.responsibility.booking', null);
    },
    reportingUser() {
      return getValue(this, 'damage.reportingUser', null);
    },
    investigation() {
      return getValue(this, 'damage.investigation', null);
    },
    hasInvestigationInProgress() {
      return getValue(this, 'investigation.status', undefined) === INVESTIGATION_STATUS.inProgress;
    },
    hasInvestigationClosed() {
      return getValue(this, 'investigation.status', undefined) === INVESTIGATION_STATUS.closed;
    },
    canStartInvestigation() {
      return this.hasSameValues && this.inputs.status === DAMAGES_STATUS.notRepaired && !this.investigation;
    },
    isRejectedStatus() {
      return this.inputs.status === DAMAGES_STATUS.rejected;
    },
    isNotExteriorDamageType() {
      return this.inputs.damageType && !EXTERIOR_DAMAGE_TYPES.includes(this.inputs.damageType);
    },
    isEditing() {
      return !!this.damageUuid;
    },
    hasSameValues() {
      const currentData = omit(this.dataForRequest.data, ['attachments', 'attachmentsToDelete', 'vehicleUuid']);
      return areObjectsEqual(currentData, this.initialInputs) && !this.attachments.length && !this.attachmentsToDelete.length;
    },
    vehicleName() {
      return `${this.vehicle.brand} ${this.vehicle.model} (${sentenceCase(this.vehicle.color)})`;
    },
    originatorUser() {
      return this.isEditing ? this.damage.originatorUser : this.userAccount;
    },
    attachmentsTransformed() {
      return map(this.attachments, attachment => ({
        imageUrl: `data:image/png;base64,${base64WithoutName(attachment)}`,
        imageName: getImageName(attachment),
        isNewAttachment: true,
      }));
    },
    isDamagePointFilled() {
      const { side, coordinateX, coordinateY } = this.inputs.damage;
      return !!(side && coordinateX && coordinateY);
    },
    attachmentsList() {
      return [...this.currentAttachments, ...this.attachmentsTransformed];
    },
    attachmentsSchema() {
      return DAMAGE_ATTACHMENT_SCHEMA(this.openPreviewModal);
    },
    attachmentsForPreview() {
      return map(this.attachmentsList, ({ imageUrl, imageName }) => ({ src: imageUrl, label: imageName }));
    },
    dataForRequest() {
      const data = cloneDeep(this.inputs);
      data.reportingDate = data.reportingDate ? moment(data.reportingDate).format(DATE_FORMAT.dateTime) : null;
      data.vehicleUuid = this.vehicleUuid;
      data.attachments = [];
      data.damageImage = data.damageImage ? removeBase64Header(data.damageImage) : data.damageImage;
      each(this.attachments, attachment => {
        data.attachments.push(base64WithoutName(attachment));
      });
      return this.isEditing ? { damageUuid: this.damageUuid, data: { ...omit(data, ['reportingDate']), attachmentsToDelete: this.attachmentsToDelete } } : { data };
    },
  },
  created() {
    this.fleet = fleet;
    this.crm = crm;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DATE_FORMAT = DATE_FORMAT;
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DAMAGES_STATUS = DAMAGES_STATUS;
    this.DAMAGES_STATUS_COLORS = DAMAGES_STATUS_COLORS;
    this.DAMAGES_TYPES = DAMAGES_TYPES;
    this.FILE_TYPES = FILE_TYPES;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.DATE_INPUT_VALIDATIONS = DATE_INPUT_VALIDATIONS;
    this.carsharingRoutes = carsharingRoutes;
    this.attachmentsRowActions = [
      {
        label: 'Preview attachment',
        labelClass: 'emobg-font-weight-semibold',
        action: this.openPreviewModal,
      },
      {
        label: 'Download attachment',
        labelClass: 'emobg-font-weight-semibold',
        action: attachment => downloadFile({ url: attachment.imageUrl, name: attachment.imageName || getImageName(attachment.imageUrl) }),
      },
      {
        label: 'Delete attachment',
        labelClass: 'emobg-color-danger emobg-font-weight-semibold',
        action: (attachment) => {
          this.attachmentToDelete = attachment;
          this.isDeleteAttachmentOpen = true;
        },
      },
    ];

    if (this.isEditing && this.damage) {
      this.inputs.status = this.damage.status;
      this.inputs.damage = this.damage.damage;
      this.inputs.vehicleArea = this.damage.vehicleArea;
      this.inputs.damageType = this.damage.damageType;
      this.inputs.description = this.damage.description || '';
      this.inputs.reportingUserUuid = getValue(this, 'damage.reportingUser.uuid', null);
      this.inputs.responsibleUserUuid = getValue(this, 'responsibleUser.uuid', null);
      this.inputs.bookingUuid = getValue(this, 'responsibleBooking.uuid', null);
      this.inputs.invoiceUuid = getValue(this, 'damage.responsibility.invoice', null);
      this.currentAttachments = cloneDeep(this.damage.attachments);

      this.initialInputs = omit(cloneDeep(this.inputs), ['reportingDate']);
    } else {
      this.initialInputs = cloneDeep(this.inputs);
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.vehicleDamages, [
      'getVehicleDamage',
      'postVehicleDamage',
      'putVehicleDamage',
    ]),
    ...mapActions(DOMAINS_MODEL.carsharing.investigation, [
      'postInvestigation',
    ]),
    getValue,
    map,
    upperFirst,
    sentenceCase,
    base64WithoutName,
    reformatDateTime,
    async startInvestigation() {
      if (this.canStartInvestigation) {
        await this.postInvestigation(this.damageUuid);

        if (!this.newInvestigationStatus.ERROR) {
          this.$router.push({
            name: fleet.damages.investigation,
            params: {
              vehicleUuid: this.vehicleUuid,
              damageUuid: this.damageUuid,
            },
          }).catch(navigationErrorHandler);
        }
      }
    },
    onLeaveForm() {
      this.isLeaveModalOpen = false;
      this.leaveWithoutSaving();
    },
    onStatusChange() {
      this.isEditingStatus = false;

      this.$nextTick(() => {
        const targetValidatorMethod = !this.inputs.description && !this.isRejectedStatus ? 'reset' : 'validate';
        invoke(this, `$refs.descriptionField.$el.MuiValidationManager.${targetValidatorMethod}`);
      });
    },
    onDeleteAttachment() {
      const targetArray = this.attachmentToDelete.isNewAttachment ? this.attachmentsTransformed : this.currentAttachments;
      const indexToDelete = findIndex(targetArray, ({ imageUrl }) => imageUrl === this.attachmentToDelete.imageUrl);
      const targetDeleteMethod = this.attachmentToDelete.isNewAttachment ? this.$refs.fileUploader.removeFile : this.$refs.fileUploader.removeExistingFile;

      targetDeleteMethod(this.attachmentToDelete, indexToDelete);

      if (!this.attachmentToDelete.isNewAttachment) {
        this.attachmentsToDelete.push(this.attachmentToDelete.imageUuid);
      }
    },
    openPreviewModal(_document, index) {
      this.startIndex = index;
      this.isPreviewModalOpen = true;
    },
    closeModal() {
      this.isPreviewModalOpen = false;
      this.isDeleteAttachmentOpen = false;
      this.isLegalCompliantGuardModalOpen = false;
      this.startIndex = 0;
      this.attachmentToDelete = null;
    },
    goToVehicleDamagesList() {
      this.$router.push({
        name: fleet.vehicles.detail.damages,
        params: {
          vehicleUuid: this.vehicleUuid,
          refreshList: true,
        },
      }).catch(navigationErrorHandler);
    },
    saveDamageOrShowGuardModal() {
      const isCurrentStatusNotRepaired = getValue(this, 'damage.status', '') === DAMAGES_STATUS.notRepaired;

      if (this.inputs.status === DAMAGES_STATUS.notRepaired && !isCurrentStatusNotRepaired) {
        this.isLegalCompliantGuardModalOpen = true;
      } else {
        this.saveDamage();
      }
    },
    async saveDamage() {
      const request = this.isEditing ? this.putVehicleDamage : this.postVehicleDamage;
      const action = this.isEditing ? 'edited' : 'reported';
      await request(this.dataForRequest);

      if (this.newDamageStatus.ERROR) {
        this.$notify({
          message: 'We could not Report new damage. Try again.',
          type: NOTIFICATION_TYPES.error,
        });

        return;
      }

      this.$notify({
        message: `Damage successfully <span class="emobg-font-weight-semibold">${action}</span>`,
      });

      if (this.isEditing) {
        await this.getVehicleDamage({ damageUuid: this.damageUuid });
      } else {
        this.initialInputs = omit(this.dataForRequest.data, ['attachments', 'attachmentsToDelete', 'vehicleUuid']);
        this.$router.push({
          name: fleet.damages.edit,
          params: {
            vehicleUuid: this.vehicleUuid,
            damageUuid: this.newDamageUuid,
          },
        }).catch(navigationErrorHandler);
      }
    },
  },
};
</script>
<template>
  <div class="VehicleDamageForm d-flex flex-column flex-fill">
    <h1 class="mb-2">
      {{ isEditing ? 'Edit damage' : 'Report new damage' }}
    </h1>
    <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
      <div class="d-flex flex-column emobg-border-1 emobg-border-color-ground-light emobg-background-color-white">
        <h2 class="px-3 py-4 emobg-font-large emobg-background-color-ground-lightest">
          Vehicle details
        </h2>
        <div class="px-3 py-4">
          <PermissionLink
            :to="{
              name: fleet.vehicles.detail.details,
              params: {
                vehicleUuid: getValue(vehicle, 'uuid', null),
              },
            }"
            :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingVehicles]"
            class="d-block pb-1 emobg-font-weight-semibold"
            data-test-id="vehicle_details-link"
          >
            {{ vehicle.licensePlate }}
          </PermissionLink>
          {{ vehicleName }}
        </div>
        <h2 class="px-3 py-4 emobg-font-large emobg-background-color-ground-lightest">
          Damage details
        </h2>
        <ui-alert
          :color="COLORS.warning"
          :icon="ICONS.alertAloneFull"
          class="d-flex px-3 pb-4 pt-3"
        >
          Ensure that no third-party information is visible on the damage picture, such as people or personal details.
        </ui-alert>
        <div class="row px-3">
          <div class="col-lg-3">
            <div class="mb-3">
              <ContentCellComponent
                v-if="!isEditingStatus"
                label="Damage status"
              >
                <div class="d-flex align-items-center">
                  <ui-badge
                    :text="sentenceCase(inputs.status)"
                    :color="DAMAGES_STATUS_COLORS[inputs.status]"
                    :class="{ disabled: hasInvestigationInProgress }"
                  />
                  <ui-icon
                    v-if="!hasInvestigationInProgress"
                    :icon="ICONS.edit"
                    :color="GRAYSCALE.inkLight"
                    :size="ICONS_SIZES.small"
                    class="ml-1 cursor-pointer"
                    data-test-id="edit_status-action"
                    @click="isEditingStatus = true"
                  />
                </div>
              </ContentCellComponent>
              <div v-else>
                <MuiSelect
                  v-model="inputs.status"
                  v-validate="{
                    isRequired: true,
                  }"
                  :options="map(filteredDamageStatuses, status => ({ label: sentenceCase(status), value: status }))"
                  data-test-id="status-select"
                  label="Damage status"
                  placeholder="Select"
                  name="status"
                  class="w-100"
                  @change="onStatusChange"
                >
                  <template #selected="{ item }">
                    <ui-badge
                      :text="item.label"
                      :color="DAMAGES_STATUS_COLORS[item.value]"
                    />
                  </template>
                  <template #item="{ item }">
                    <div class="w-100 d-flex align-items-center justify-content-between">
                      <span class="emobg-font-weight-semibold">{{ item.label }}</span>
                      <ui-badge
                        :color="DAMAGES_STATUS_COLORS[item.value]"
                        circle
                      />
                    </div>
                  </template>
                </MuiSelect>
              </div>
            </div>
            <div class="mb-3">
              <DamageImageUploader
                v-validate.update:image="{
                  ...getValue(damage, 'imageUrl', null) ? {} : { isRequired: true }
                }"
                :image="getValue(damage, 'imageUrl', null)"
                @update:image="image => inputs.damageImage = image"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-3">
              <VehicleDamageArea
                v-model="inputs.damage"
                :sides="vehicle.images"
              />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="mb-3">
              <MuiAlgoliaSelect
                v-model="inputs.vehicleArea"
                v-validate="{
                  isRequired: true,
                }"
                :title="({ internal_name }) => upperFirst(internal_name.replace(/\_/g, ' '))"
                :index="ALGOLIA_INDEXES.vehicleAreas"
                :filters="`vehicle_type: ${getValue(vehicle, 'type', '')}`"
                label="Vehicle area*"
                path-value="internal_name"
                class="w-100"
                placeholder="Select"
                name="area"
                data-test-id="area-select"
              />
            </div>
            <div class="mb-3">
              <MuiSelect
                v-model="inputs.damageType"
                v-validate="{
                  isRequired: true,
                }"
                :options="map(DAMAGES_TYPES, type => ({ label: sentenceCase(type), value: type }))"
                label="Damage type*"
                class="w-100"
                placeholder="Select"
                name="type"
                data-test-id="type-select"
              />
              <span
                v-if="isNotExteriorDamageType"
                class="d-block emobg-font-x-small m-1"
              >
                Damage picture won't be shown to app user.
              </span>
            </div>
            <div class="mb-3">
              <label class="d-block emobg-font-weight-semibold mb-1">
                Description{{ isRejectedStatus ? '*' : '' }}
              </label>
              <MuiTextarea
                ref="descriptionField"
                v-model="inputs.description"
                v-validate="{
                  ...(isRejectedStatus) ? { isRequired: { message: 'Please provide the reason for rejecting the damage.' } } : {},
                  isMinLength: {
                    message: 'We need at least 6 characters',
                    length: 6,
                  }
                }"
                placeholder="Enter"
                data-test-id="description-textarea"
                name="description"
                rows="16"
              />
            </div>
          </div>
        </div>

        <div class="mb-4 px-3">
          <div class="d-flex flex-fill justify-content-between align-items-center">
            <label class="d-block align-self-end emobg-font-default emobg-font-weight-semibold">
              Attachments
            </label>

            <DragFileComponent
              ref="fileUploader"
              v-model="attachments"
              :existing-files="currentAttachments"
              :accepted-formats="`${FILE_TYPES.image},${FILE_TYPES.pdf}`"
              hidden
              multiple
              @existingFileRemoved="files => currentAttachments = files"
            >
              <ui-button :face="FACES.outline">
                Upload attachment
              </ui-button>
            </DragFileComponent>
          </div>

          <div class="emobg-border-color-ground-light emobg-border-top-1 mt-2 mb-3" />

          <TableComponent
            :schema="attachmentsSchema"
            :data="attachmentsList"
            :row-actions="attachmentsRowActions"
            empty-label="No attachments yet"
          />
        </div>

        <div class="px-3 py-4">
          <h2 class="emobg-font-default">
            Reporting source
          </h2>
          <div class="emobg-border-color-ground-light emobg-border-top-1 mt-2 mb-4" />
          <div class="w-100 row">
            <div class="col-md-6 col-lg-4">
              <ContentCellComponent label="Agent">
                <RouterLink
                  :to="{
                    name: crm.users.detail.index,
                    params: {
                      userUuid: getValue(originatorUser, 'uuid', null),
                    },
                  }"
                  class="cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none"
                  data-test-id="agent-link"
                >
                  {{ `${getValue(originatorUser, 'firstName', '')} ${getValue(originatorUser, 'lastName', '')}` }}
                </RouterLink>
              </ContentCellComponent>
            </div>

            <div class="col-md-6 col-lg-4 mb-3">
              <ContentCellComponent
                v-if="isEditing"
                label="Reporting user"
              >
                <RouterLink
                  v-if="reportingUser"
                  :to="{
                    name: crm.users.detail.index,
                    params: {
                      userUuid: getValue(reportingUser, 'uuid', null),
                    },
                  }"
                  class="cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none"
                  data-test-id="reporting_user-link"
                >
                  {{ `${getValue(reportingUser, 'firstName', '')} ${getValue(reportingUser, 'lastName', '')} - ${getValue(reportingUser, 'email', '')}` }}
                </RouterLink>
                <span v-else> {{ FALLBACK_MESSAGE.dash }} </span>
              </ContentCellComponent>
              <MuiAlgoliaSelect
                v-else
                v-model="inputs.reportingUserUuid"
                v-validate="{
                  isRequired: true,
                }"
                :title="({ first_name, last_name, email }) => `${first_name} ${last_name} - ${email}`"
                :index="ALGOLIA_INDEXES.users"
                :filters="`cs_operator_uuid: ${activeOperatorUuid} OR employee.company.cs_operator_uuid: ${activeOperatorUuid}`"
                label="Reporting user*"
                path-value="uuid"
                class="w-100"
                placeholder="Select"
                name="reportingUser"
                data-test-id="reporting_user-select"
              />
            </div>

            <div class="col-md-6 col-lg-4 mb-3">
              <ContentCellComponent
                v-if="isEditing"
                :value="damage ? reformatDateTime(getValue(damage, 'reportingDate', null), DATE_FORMAT.defaultExtended, operatorTimezone) : null"
                label="Reporting date"
              />
              <MuiDatePicker
                v-else
                v-model="inputs.reportingDate"
                v-validate="{
                  isRequired: true,
                  isValidDate: DATE_INPUT_VALIDATIONS.validDate,
                }"
                :date-format-key="DATE_FORMAT_KEYS.defaultExtended"
                data-test-id="reporting_date-select"
                label="Reporting date*"
                name="reportingDate"
                class="w-100"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isEditing"
        class="d-flex flex-column emobg-border-1 emobg-border-color-ground-light emobg-background-color-white mt-5"
      >
        <h2 class="px-3 py-4 emobg-font-large emobg-background-color-ground-lightest">
          Responsibility details
        </h2>
        <div class="px-3 py-4">
          <ui-alert
            v-if="!hasInvestigationClosed"
            :icon="canStartInvestigation ? ICONS.bold.check : ICONS.infoFull"
            :color="canStartInvestigation ? COLORS.success : COLORS.primary"
            class="d-flex mb-3"
          >
            <template v-if="canStartInvestigation">
              Now you can <strong class="emobg-font-weight-bold">Start the investigation.</strong>
            </template>
            <template v-else-if="hasInvestigationInProgress">
              Investigation is <strong class="emobg-font-weight-bold">In progress</strong>. Damage status cannot be changed until investigation is closed.
            </template>
            <template v-else>
              To Start investigation, first <strong class="emobg-font-weight-bold">save</strong> the changes and make sure damage
              status is <strong class="emobg-font-weight-bold">Not Repaired.</strong>
            </template>
          </ui-alert>

          <div class="d-flex flex-fill justify-content-between align-items-center">
            <h2 class="emobg-font-default align-self-end">
              Investigation resolution
            </h2>

            <ui-button
              :disabled="!canStartInvestigation || newInvestigationStatus.LOADING"
              :loading="newInvestigationStatus.LOADING"
              @clickbutton="startInvestigation"
            >
              Start investigation
            </ui-button>
          </div>
          <div class="emobg-border-color-ground-light emobg-border-top-1 my-2" />
          <p class="emobg-color-ink-light mb-4">
            Start an investigation to identify the user responsible for the damage
          </p>

          <InvestigationTableComponent
            :investigation="investigation"
            :vehicle-uuid="vehicleUuid"
            :damage-uuid="damageUuid"
            class="mb-6"
          />

          <h2 class="emobg-font-default">
            Responsibility
          </h2>
          <div class="emobg-border-color-ground-light emobg-border-top-1 my-2" />
          <p class="emobg-color-ink-light mb-4">
            This section is filled automatically based on the Investigation resolution.
          </p>
          <div class="w-100 row">
            <div class="col-md-6 col-lg-4">
              <ContentCellComponent label="Responsible user">
                <RouterLink
                  v-if="responsibleUser"
                  :to="{
                    name: crm.users.detail.index,
                    params: {
                      userUuid: getValue(responsibleUser, 'uuid', null),
                    },
                  }"
                  class="cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none"
                  data-test-id="responsible-link"
                >
                  {{ `${getValue(responsibleUser, 'firstName', '')} ${getValue(responsibleUser, 'lastName', '')}` }}
                </RouterLink>
              </ContentCellComponent>
            </div>

            <div class="col-md-6 col-lg-4">
              <ContentCellComponent label="Booking ID">
                <RouterLink
                  v-if="responsibleBooking"
                  :to="{
                    name: carsharingRoutes.bookings.detail.index,
                    params: {
                      bookingUuid: getValue(responsibleBooking, 'uuid', null),
                    },
                  }"
                  class="cursor-pointer emobg-color-primary emobg-font-weight-semibold text-decoration-none"
                  data-test-id="booking-link"
                >
                  {{ `#${getValue(responsibleBooking, 'id', '')}` }}
                </RouterLink>
              </ContentCellComponent>
            </div>

            <div class="col-md-6 col-lg-4">
              <MuiAlgoliaSelect
                v-model="inputs.invoiceUuid"
                :disabled="!inputs.responsibleUserUuid"
                :title="({ serie, number, date_ts }) => `${serie} ${number} - ${moment(date_ts, 'X').format(DATE_FORMAT.date)}`"
                :filters="`cs_operator_fk: ${activeOperatorId} AND user_uuid: ${inputs.responsibleUserUuid}`"
                :index="ALGOLIA_INDEXES.invoices"
                label="Invoice number"
                path-value="uuid"
                class="w-100"
                placeholder="Select"
                name="invoice"
                data-test-id="invoice-select"
              />
            </div>
          </div>
        </div>
      </div>
    </MuiValidationWrapper>

    <div class="BottomActions d-flex p-3 emobg-border-1 emobg-border-color-ground-light position-sticky">
      <ui-button
        :color="GRAYSCALE.inkLight"
        :face="FACES.text"
        class="mr-2"
        data-test-id="close-button"
        @clickbutton="goToVehicleDamagesList"
      >
        Close
      </ui-button>
      <ui-button
        :disabled="!isFormValid || !isDamagePointFilled || hasSameValues"
        :loading="newDamageStatus.LOADING"
        data-test-id="save-button"
        @clickbutton="saveDamageOrShowGuardModal"
      >
        {{ isEditing ? 'Save' : 'Report new damage' }}
      </ui-button>
    </div>
    <AttachmentPreviewModal
      v-if="isPreviewModalOpen"
      :attachments="attachmentsForPreview"
      :start-index="startIndex"
      @closeModal="closeModal"
    />

    <DeleteAttachmentModal
      v-if="isDeleteAttachmentOpen"
      :attachment="attachmentToDelete"
      :callback="onDeleteAttachment"
      @closeModal="closeModal"
    />
    <LegalCompliantGuardModal
      v-if="isLegalCompliantGuardModalOpen"
      :image="inputs.damageImage || getValue(damage, 'imageUrl', null)"
      :callback="saveDamage"
      @closeModal="closeModal"
    />
    <LeaveWithoutSavingModal
      v-if="isLeaveModalOpen"
      data-test-id="leave_modal"
      @on:leave="onLeaveForm"
      @closeModal="isLeaveModalOpen = false"
    />
  </div>
</template>
<style lang="scss">
  .VehicleDamageForm {
    .BottomActions {
      bottom: 0;
      z-index: 1;
      align-items: center;
      justify-content: end;
      background-color: #fff;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.32);
    }

    .Ui-TextInput--disabled {
      background-color: initial;
    }
  }
</style>
