var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "InvestigationTableComponent" },
    [
      _c(_setup.TableComponent, {
        attrs: {
          schema: _setup.investigationSchema,
          data: _setup.investigationData,
          "row-actions": _setup.actions,
          "empty-label": "No investigation has started yet",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }